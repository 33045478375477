import styled, { keyframes } from 'styled-components'
import { DEVICE } from '../../../util/constants'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const animateUp = keyframes`
  from {
    transform: translateY(30px);
  }

  to {
    transform: none;
  }
`

export const Container = styled.div`
  animation: ${fadeIn} 100ms 200ms ease-in-out both;
  align-items: center;
  background-color: rgb(0 0 0 / 50%);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`

export const ClickTarget = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 140px 30px 10px;
  width: 100%;

  @media ${DEVICE.tabletL} {
    padding: 60px 30px 10px;
  }
`

export const ContentContainer = styled.div`
  animation: ${animateUp} 400ms 100ms ease-in-out both;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 250px;
  min-width: 250px;
  padding: 20px;
  position: relative;
  text-align: center;
  z-index: 1;

  @media ${DEVICE.tabletL} {
    padding: 30px;
    max-width: 640px;
    min-width: 640px;
    width: 50%;
  }
`

export const CloseButton = styled.button`
  align-items: center;
  background: none;
  border: 2px solid var(--content-background-light);
  border-radius: 50%;
  display: flex;
  height: 56px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: -130px;
  width: 56px;

  @media ${DEVICE.tabletL} {
    right: -70px;
    top: -50px;
  }
`

export const CloseButtonIcon = styled.span`
  background: none;
  display: block;
`

export const CloseButtonLabel = styled.span`
  background: none;
  bottom: -20px;
  color: var(--content-background-light);
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  left: 0;
  line-height: 1.357;
  position: absolute;
  text-align: center;
  right: 0;
  white-space: nowrap;
  width: 100%;

  @media ${DEVICE.tabletL} {
    bottom: -25px;
  }
`

export const Picture = styled.picture`
  display: block;
  height: auto;
  margin: -60px 0 30px;
  max-height: 300px;
  object-fit: cover;
  width: 100%;

  @media ${DEVICE.tabletL} {
    margin: -80px 0 50px;
  }
`

export const Copy = styled.h3`
  color: var(--highlight-blue);
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 1.357;

  @media ${DEVICE.tabletL} {
    font-size: 4.8rem;
  }
`

export const Cta = styled.a`
  align-items: center;
  align-self: center;
  background-color: transparent;
  border: 2px solid var(--highlight-blue);
  border-radius: 5px;
  color: var(--content-background-dark-blue);
  display: flex;
  justify-content: center;
  margin-top: 30px;
  min-width: 122px;
  padding: 18px 34px;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: var(--highlight-blue);
    color: #fff;
  }

  @media ${DEVICE.tabletL} {
    margin-top: 50px;
    padding: 22px 40px;
  }
`

export const CtaLabel = styled.span`
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.02em;

  @media ${DEVICE.tabletL} {
    font-size: 2rem;
  }
`
